var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"wizard wizard-1",attrs:{"id":"kt_wizard_v1","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_c('div',{staticClass:"row justify-content-center my-10 px-8 my-lg-15 px-lg-10"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[_c('h5',{staticClass:"font-weight-bolder text-dark font-size-h6 font-size-h3-lg"},[_vm._v(" Detail Tahun Ajaran ")]),_c('table',{staticClass:"table mt-8"},[_c('tr',[_vm._m(0),_vm._m(1),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.name))])])]),_c('tr',[_vm._m(2),_vm._m(3),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.start_periode_display))])])]),_c('tr',[_vm._m(4),_vm._m(5),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.end_periode_display))])])]),_c('tr',[_vm._m(6),_vm._m(7),_c('td',{staticClass:"pl-2"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.detail.periode_type_name))])])])])]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('div',[_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.$router.push('/masters/school-years')}}},[_vm._v(" Tutup ")]),(_vm.btnAccess)?_c('b-button',{staticClass:"ml-2",attrs:{"type":"button","variant":"success"},on:{"click":function($event){return _vm.$router.push('/masters/school-years/edit/' + _vm.$route.params.id)}}},[_vm._v(" Edit ")]):_vm._e()],1)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-1",attrs:{"width":"100"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nama")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0 pr-0",attrs:{"width":"10"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Awal Periode")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Akhir Periode")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pr-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Jenis Periode")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"pl-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(":")])])
}]

export { render, staticRenderFns }